<template>
  <div class="fixed left-0 right-0 bottom-0 shadow-double bg-white-900">
    <div class="container mx-auto">
      <div class="flex justify-end py-2">
        <!-- <base-button class="py-3 max-w-146 w-full">
          <span class="font-normal text-sm"> Contacts </span>
        </base-button> -->

        <base-button
          colorScheme="orange"
          class="ml-3 py-3 max-w-146 w-full"
          @click.native="$emit('exportToPdf')"
        >
          <span class="font-normal text-sm"> Export all </span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/utils/Button.vue";
export default {
  name: "StickyButtons",
  components: {
    "base-button": BaseButton,
  },
};
</script>
