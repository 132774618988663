<template>
  <div>
    <ul class="flex items-center">
      <li
        v-for="(link, index) in linksList"
        :key="index"
        class="flex items-center text-base text-grey-700"
      >
        <span v-if="index > 0">&nbsp;>&nbsp;</span>
        <router-link
          v-if="link.href"
          :to="link.href"
          class="hover:text-black-900"
        >
          {{ link.text }}
        </router-link>
        <span v-else>{{ link.text }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    linksList: {
      type: Array,
      required: true,
    },
  },
};
</script>
