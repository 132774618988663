<template>
  <div>
    <div class="bg-white-900 mb-14 mt-2">
      <div class="container mx-auto">
        <base-breadcrumbs :links-list="breadcrumbsList" class="mb-6" />
        <h1
          v-if="!isShortlistPublisherBooksLoading"
          class="capitalize text-5xl text-black-900 mb-5"
        >
          Shortlist of `{{ shortlistPublisherBooks.shortlist.name }} -
          {{ shortlistPublisherBooks.publisher.name }}` Books
        </h1>
        <list-books
          class="mb-13"
          :isBooksLoading="isShortlistPublisherBooksLoading"
          :books="shortlistPublisherBooks.books"
          :isShortlistPage="true"
        />
      </div>
      <sticky-buttons @exportToPdf="exportToPdf" />
    </div>

    <div class="vue-html2pdf">
      <transition name="transition-anim">
        <section class="pdf-preview" v-if="pdfFile">
          <button @click.self="closePdf()">&times;</button>
          <iframe :src="pdfFile" width="100%" height="100%" />
        </section>
      </transition>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

import Breadcrumbs from "@/components/base/Breadcrumbs.vue";
import StickyButtons from "@/components/base/StickyButtons.vue";
import TabBooks from "@/components/tabs/Books.vue";
import { FETCH_SHORTLIST_PUBLISHER_BOOKS } from "@/store/actions.type";
import { ShortlistService } from "@/common/api.service";

export default {
  name: "ShortlistBooks",
  components: {
    "base-breadcrumbs": Breadcrumbs,
    "sticky-buttons": StickyButtons,
    "list-books": TabBooks,
  },
  async mounted() {
    await this.$store.dispatch(FETCH_SHORTLIST_PUBLISHER_BOOKS, {
      slug: this.$route.params.id,
      publisherSlug: this.$route.params.publisherHandle,
    });
    Vue.set(this.breadcrumbsList, 1, {
      ...this.breadcrumbsList[1],
      text: this.shortlistPublisherBooks.shortlist.name,
    });
    Vue.set(this.breadcrumbsList, 2, {
      ...this.breadcrumbsList[2],
      text: this.shortlistPublisherBooks.publisher.name,
    });
  },
  computed: {
    ...mapGetters([
      "isShortlistPublisherBooksLoading",
      "shortlistPublisherBooks",
    ]),
  },
  data() {
    return {
      breadcrumbsList: [
        {
          href: "/shortlist",
          text: "Shortlist",
        },
        {
          href: "",
          text: "",
        },
        {
          href: "",
          text: "",
        },
      ],
      pdfFile: null,
    };
  },
  methods: {
    async exportToPdf() {
      const { data } = await ShortlistService.getPublisherPdf(
        this.$route.params.id,
        this.shortlistPublisherBooks.publisher.id
      );
      const blob = new Blob([data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      this.pdfFile = blobUrl;
    },
    closePdf() {
      URL.revokeObjectURL(this.pdfFile);
      this.pdfFile = null;
    },
  },
};
</script>
